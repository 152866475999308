import React, { useState } from 'react';

import './App.css';
import { Button, Row, Container, Col, Image, Form} from 'react-bootstrap';
import BoostYourBusiness from "./image/BoostYourBusiness.jpg"
import IchecAlumniLogo from "./image/ichechAlumniLogo.jpg"


import fetch from 'node-fetch'

import 'bootstrap/dist/css/bootstrap.min.css'


function App() {

  const [validated, setValidated] = useState(false);
  const [society, setSociety] = useState(false)
  const [member, setMember] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault();

    const checkS = event.target[4].value
    const checkT = event.target[5].value
    setSociety(false)

    if(checkS.length !== 0 || checkT.length !== 0)
      setSociety(true)
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    var item = {};
    for (var i = 0; i < event.target.length; i++){
      item[event.target[i].id] = event.target[i].value
    }
    if(item){
      fetch('https://parrainage.b19app.be/form-ichec/sendinfo',{
        method:'POST',
        headers:{
        'Content-Type': 'application/json',
        'Accept': 'application/json'},
        body:JSON.stringify(item)
      }).then(response=>response.json()).then(res=>{
        console.log(res)
        if(res.error === false){
          setMember("Votre formulaire a bien été envoyé")
        } else {
          setMember("Une erreur s'est produite ou un champ a été mal complété, veuillez réessayer")
        }
      })
    }
    setValidated(true);
  };

  return (
    <div className="App">
    <div className="centerButton">


    <Row>
    <Col>
    <Image src={BoostYourBusiness} fluid />
    </Col>
    <Col style={{marginTop: 65, marginRight: 25}}>
      <Image src={IchecAlumniLogo} fluid />
    </Col>
    </Row>

    </div>

    <Container style={{marginTop: 40}}>
      <div className="center">
        <Row>
          <p style={{textAlign: 'center', marginTop: 15}}>Elargir et entretenir votre réseau professionnel sera plus important que jamais pour vous permettre de relancer le développement de vos différentes activités. Afin de vous accompagner dans cette démarche, ICHEC Alumni s’associe ainsi au Cercle d’Affaires B19 pour vous proposer une offre inédite: <br />
Pour le tarif unique de 600€ htva, vous aurez la possibilité d’être à la fois membre d'ICHEC Alumni et du Cercle d'Affaires B19. Vous pourrez ainsi assister à toutes les conférences du B19 à Bruxelles et partout ailleurs en Belgique et au Luxembourg, mais aussi à tous les événements ICHEC Alumni !</p>
          <p style={{fontWeight: 'bold'}}>Vous souhaitez devenir membre, remplissez le formulaire ci-dessous.</p>
          <p style={{marginTop: 15}}>VOTRE PARRAIN:</p>
          <p style={{fontWeight: 'bold', fontSize: 18, marginTop: 10}}>ICHEC ALUMNI</p>
        </Row>
      </div>
    </Container>

    <Container style={{marginTop: 40}}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col} controlId="formGridlastName">
            <Form.Label>Nom*</Form.Label>
            <Form.Control
            required
            type="text"
            placeholder="Entrez-votre nom" />
            <Form.Control.Feedback type="invalid">
              Ce champ est obligatoire.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridfirstName">
            <Form.Label>Prénom*</Form.Label>
            <Form.Control
            required
            type="text"
            placeholder="Entrez-votre prénom" />
            <Form.Control.Feedback type="invalid">
              Ce champ est obligatoire.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
        <Form.Group as={Col} controlId="formGridfirstMail">
          <Form.Label>E-mail*</Form.Label>
          <Form.Control
          required
          type="email"
          placeholder="Entrez-votre e-mail" />
          <Form.Control.Feedback type="invalid">
            Ce champ est obligatoire.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridTel">
          <Form.Label>Téléphone*</Form.Label>
          <Form.Control
          required
          type="text"
          placeholder="Entrez-votre numéro de téléphone" />
          <Form.Control.Feedback type="invalid">
            Ce champ est obligatoire.
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row>
        <Form.Group as={Col} controlId="formGridSociety">
          <Form.Label>Société</Form.Label>
          <Form.Control
          required = {society}
          type="text"
          placeholder="Entrez-votre société" />
          <Form.Control.Feedback type="invalid">
            Ce champ est obligatoire.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridTVA">
          <Form.Label>Nº TVA</Form.Label>
          <Form.Control
          required = {society}
          type="text"
          placeholder="Entrez-votre numéro de TVA" />
          <Form.Control.Feedback type="invalid">
            Ce champ est obligatoire.
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Code postal*</Form.Label>
            <Form.Control
            required
            type="number"
            placeholder="Entrez-votre code postal" />
            <Form.Control.Feedback type="invalid">
              Ce champ est obligatoire.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Ville*</Form.Label>
            <Form.Control
            required
            type="text"
            placeholder="Entrez-votre ville" />
            <Form.Control.Feedback type="invalid">
              Ce champ est obligatoire.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group controlId="formGridAddress">
          <Form.Label>Adresse postale*</Form.Label>
          <Form.Control
          required
          type="text"
          placeholder="Entrez-votre adresse" />
          <Form.Control.Feedback type="invalid">
            Ce champ est obligatoire.
          </Form.Control.Feedback>
        </Form.Group>


        <div className="centerButton">
        <p>{member}</p>
        </div>
        <div className="centerButton" style={{marginBottom: 15}}>

        <Button
        variant="outline-dark"
        type="submit">
          Envoyer
        </Button>
        </div>




      </Form>
      </Container>

    </div>
  );
}

export default App;
